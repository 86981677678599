import React, { Component } from 'react';
import styled               from 'styled-components';
import * as R               from 'ramda';

import {
  StarBorder,
  FileDownload,
  Flag,
  OpenInNew,
  // RotateRight,
  Block
} from '@material-ui/icons';

import Logo from '../../../../../components/Logo';

import {
  makeDecoratedImageUrl,
  getRotation,
  // getNextRotation
} from '../../../../../utils/image';

import MotiveModal from './MotiveModal';

const Container = styled.div`
  min-height : 50px;
  width      : 50%;
  position   : relative;
`;

const IconsWrapper = styled.div`
  display         : flex;
  margin          : 12px;
  padding         : 7px;
  width           : 180px;
  justify-content : space-between;
  background      : rgba(54, 54, 54, 0.74);
  border-radius   : 15px;
`;

const RejectedButton = styled.div`
  display         : flex;
  align-items     : center;
  justify-content : center;
  cursor          : pointer;
  margin          : 12px;
  height          : 25px;
  min-width       : 100px;
  border-radius   : 50px;
  font-weight     : 600;
  font-size       : 12px;
  background      : ${props => props.isRejected ? 'red' : '#fff'};
  box-shadow      : 1px 1px 4px rgba(0, 0, 0, 0.2);
  color           : #000;
`;

const ButtonsContainer = styled.div`
  position        : absolute;
  bottom          : 0;
  width           : 100%;
  display         : flex;
  align-items     : center;
  justify-content : space-between;
  box-sizing      : border-box;
`;

const styles = {
  icon : {
    color        : 'white',
    paddingRight : '.375rem',
    cursor       : 'pointer'
  },
  activeIcon : {
    color        : 'red',
    paddingRight : '.375rem',
    cursor       : 'pointer'
  },
};

const QuestionTitle = styled.p`
  text-align  : left;
  font-size   : .75rem;
  font-weight : 500;
  font-style  : oblique;
  margin-left : .5rem;
  line-height : 1.2;
  height      : 30px;
`;

const Image = styled.img`
  max-width  : 100%;
  width      : auto;
  margin     : 0 auto;
  display    : block;
  height     : 52vh;
`;

// const WRS_APP_DOCS_HOST = process.env.WRS_APP_DOCS_HOST;
// const WRS_APP_SEC_DOCS_HOST = process.env.WRS_APP_SEC_DOCS_HOST;
// const WRS_APP_REDUCED_DOCS_HOST = process.env.WRS_APP_REDUCED_DOCS_HOST;
// const WRS_APP_SEC_REDUCED_DOCS_HOST = process.env.WRS_APP_SEC_REDUCED_DOCS_HOST;

const WRS_APP_DOCS_HOST = "https://wrsprodbucket.s3.amazonaws.com";
const WRS_APP_SEC_DOCS_HOST = "https://wrsprodbucket.s3.amazonaws.com";
const WRS_APP_REDUCED_DOCS_HOST = "https://wrsprodresizedbucket.s3.amazonaws.com";
const WRS_APP_SEC_REDUCED_DOCS_HOST = "https://wrsprodresizedbucket.s3.amazonaws.com";

// const WRS_APP_DOCS_HOST = "https://wrsprodbucket.s3.amazonaws.com";
// const WRS_APP_SEC_DOCS_HOST = "https://wrsphs.mstage.wintellplus.com";
// const WRS_APP_REDUCED_DOCS_HOST = "https://wrsprodresizedbucket.s3.amazonaws.com";
// const WRS_APP_SEC_REDUCED_DOCS_HOST = "https://wrsphs.mstage.wintellplus.com";

const getSecUrl = (burl) =>
  {
   if (!R.isNil(burl) && burl.includes(WRS_APP_DOCS_HOST)) {
     const tempSecUrl = R.replace(WRS_APP_DOCS_HOST, WRS_APP_SEC_DOCS_HOST, burl);
     return tempSecUrl;
   }
   else if(!R.isNil(burl) && burl.includes(WRS_APP_REDUCED_DOCS_HOST))
   {
     const tempSecUrl = R.replace(WRS_APP_REDUCED_DOCS_HOST, WRS_APP_SEC_REDUCED_DOCS_HOST, burl);
     return tempSecUrl;
   }
   else
   {
     return burl;
   }  
  }

class PhotoContainer extends Component {
  state = {
    isRejected       : R.has(this.props.img.canonicalKey, this.props.rejectedQuestions),
    motiveModalOpen  : false,
    motiveModalType  : ""
  };

  componentDidUpdate(prevProps) {
    if (!R.equals(prevProps.img, this.props.img)) {
      this.setState({
        isRejected: R.has(this.props.img.canonicalKey, this.props.rejectedQuestions)
      });
    }
  }

  toggleReject = questionUuid => {
    const isRejected = this.state.isRejected;
    this.setState(prevState => ({ isRejected : !prevState.isRejected }));

    this.props.toggleRejectPhotoQuestion(!isRejected, questionUuid);
  };

  downloadImage = img => {

    const url   = R.pathOr('', ['document', 'url'],   img),
          title = R.pathOr('', ['document', 'title'], img); 
          
          var rotateurl270 = "https://d1l7bm88gaqr5n.cloudfront.net/fit-in/filters:rotate(270)";
          var rotateurl180 = "https://d1l7bm88gaqr5n.cloudfront.net/fit-in/filters:rotate(180)";
          var rotateurl90 = "https://d1l7bm88gaqr5n.cloudfront.net/fit-in/filters:rotate(90)";
          var rotateurl0 = "https://d1l7bm88gaqr5n.cloudfront.net/fit-in/filters:rotate(0)";
      
          var curl = "";
          if (url.includes(rotateurl270)) {
            curl = url.replace(rotateurl270, "");
            curl = WRS_APP_SEC_DOCS_HOST + curl;
          }
          else if (url.includes(rotateurl180)) {
            curl = url.replace(rotateurl180, "");
            curl = WRS_APP_SEC_DOCS_HOST + curl;
          }
          else if (url.includes(rotateurl90)) {
            curl = url.replace(rotateurl90, "");
            curl = WRS_APP_SEC_DOCS_HOST + curl;
          }
          else if (url.includes(rotateurl0)) {
            curl = url.replace(rotateurl0, "");
            curl = WRS_APP_SEC_DOCS_HOST + curl;
          }   
          
          const tempSecUrl = getSecUrl(url);

    let xhr = new XMLHttpRequest();
    xhr.open('GET', R.isEmpty(curl) ? tempSecUrl : curl , true);
    xhr.responseType = 'blob';
    xhr.onload       = function() {
      const urlCreator = window.URL || window.webkitURL;
      const imageUrl = urlCreator.createObjectURL(this.response);

      let a      = document.createElement('a');
      a.href     = imageUrl;
      a.download = title;

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    };
    xhr.send();
  };

  render () {
    const { isRejected } = this.state;
    const {
      img,
      onFlag,
      onBlock,
      onStar,
      isClientUser,
      blockPhotos,
      reviewMode
    } = this.props;

    const { flag, block, star } = img;
    const { icon, activeIcon }  = styles;

    const imageUrl      = R.pathOr('', ['document', 'url'], img);
    const questionTitle = R.propOr('', 'title',             img);
    const questionUuid  = R.propOr('', 'uuid',              img);

    const isBlockedPhoto = R.isEmpty(img) || (isClientUser && !R.isNil(R.find(R.eqProps('uuid', img.document))(blockPhotos)));

    return (
      <Container>
        <QuestionTitle>{questionTitle}</QuestionTitle>
        <div style={{ backgroundColor : '#000' }}>
        {
          !isBlockedPhoto ? (
            <Image src={ makeDecoratedImageUrl(getRotation(imageUrl), 1280)(imageUrl) }/>
          ) : (
            <Logo style={{ maxHeight : '400px' }}/>
          )
        }
        </div>
        {
          isClientUser ? (
            <ButtonsContainer>
              <IconsWrapper style={{ width : '70px' }}>
                <FileDownload
                  name    = "FileDownload"
                  style   = {styles.icon}
                  onClick = {() => this.downloadImage(img)}
                />
                <OpenInNew
                  name    = "OpenInNew"
                  style   = {styles.icon}
                  onClick = {() => window.open(`${ imageUrl }`)}
                />
              </IconsWrapper>
            </ButtonsContainer>
          ) : (
            <ButtonsContainer>
              <IconsWrapper>
                <StarBorder
                  name    = "StarBorder"
                  style   = {star ? activeIcon : icon}
                  onClick={() => onStar(star)}
                  // onClick = {() => !star ? this.setState( { motiveModalOpen: true, motiveModalType: "star" } ) : onStar(star, '')}
                />
                <Block
                  name    = "Block"
                  style   = {block ? activeIcon : icon}
                  onClick = {() => onBlock(block)}
                />
                <Flag
                  name    = "Flag"
                  style   = {flag ? activeIcon : icon}
                  onClick={() => onFlag(flag)}
                  // onClick = {() => !flag  ? this.setState( { motiveModalOpen: true, motiveModalType: "flag" } ) : onFlag(flag, '')}
                />
                <FileDownload
                  name    = "FileDownload"
                  style   = {styles.icon}
                  onClick = {() => this.downloadImage(img)}
                />
                <OpenInNew
                  name    = "OpenInNew"
                  style   = {styles.icon}
                  onClick = {() => window.open(`${ imageUrl }`)}
                />
                {/* <RotateRight
                  name    = "RotatePhoto"
                  style   = {styles.icon}
                  onClick = {() => this.props.updateDocument(img.document.uuid, {
                    url : makeDecoratedImageUrl(getNextRotation(imageUrl), 'full')(imageUrl)
                  }, this.props.namespace)} 
                />*/}
              </IconsWrapper>
              {reviewMode && (
                <RejectedButton
                  isRejected = {isRejected}
                  onClick    = {() => this.toggleReject(questionUuid)}
                >
                  REJECT
                </RejectedButton>
              )}
            </ButtonsContainer>
          )
        }
        <MotiveModal 
          open={this.state.motiveModalOpen} 
          onClose={() => this.setState({ motiveModalOpen: false })}
          label={this.state.motiveModalType === 'star' ? "Star photo motive" : "Flag photo motive"}
          onSubmit={value => {
            this.setState({ motiveModalOpen: false });
            this.state.motiveModalType === 'star'
              ? onStar && onStar(star, value)
              : onFlag && onFlag(flag, value);
          }}/>
      </Container>
    );
  }
}

export default PhotoContainer;
