import * as R               from 'ramda';
import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import styled               from 'styled-components';
import { withStyles }       from '@material-ui/core/styles';
import moment               from 'moment';

import HeroImageUploader from './HeroImageUploader';

import {
  MenuItem,
  TextField,
  Select,
  InputLabel,
  Input,
  FormControl
} from '@material-ui/core';

const Wrapper = styled.div`
  display        : flex;
  flex-direction : row;
`;

const TitleWrapper = styled.div`
  flex    : 2;
  padding : 0 20px;

  & > div {
    margin-top : 10px;
    width      : 300px
  }

  & > div > label {
    font-weight : bold;
    font-size   : 17px;
    color       : #000;
  }
`;

const CallOutMailingWrapper = styled.div`
  flex    : 2;
  padding : 0 20px;

  & > div {
    margin-top : 10px;
    width      : 640px
  }

  & > div > label {
    font-weight : bold;
    font-size   : 17px;
    color       : #000;
  }
`;

const SaveWrapper = styled.div`
  flex            : 9;
  display         : flex;
  align-items     : flex-end;
  flex-direction  : column;
  padding-left    : 20px;
  margin-bottom   : 10px;
`;

const VersionInformation = styled.div`
  text-align: right;
  flex: 1;
`;

const VersionInformationSpan = styled.span`
  display      : block;
  font-size    : 13px;
  & + & {
    margin-top : 5px;
  }
`;

const SaveWrapperButtons = styled.div`
  flex            : 9;
  display         : flex;
  align-items     : flex-start;
  justify-content : flex-end;
  margin-bottom: 10px;
`;

const ButtonWrapper = styled.button`
  padding       : 10px 20px;
  background    : transparent;
  color         : #c2403d;
  border        : 2px solid #d58c8d;
  border-radius : 5px;
  font-size     : 13px;
  font-weight   : 500;
  cursor        : pointer;
  transition    : .3s all;
  & + & {
    margin-left   : 20px;
  }

  &:hover,
  &:active {
    color        : white;
    border-color : #d58c8d;
    background    : #c2403d;
  }

  &:disabled {
    opacity : .5;
    cursor  : not-allowed;
  }
`;

const styles = theme => ({
  root        : {
    display  : 'flex',
    flexWrap : 'wrap'
  },
  formControl : {
    margin     : theme.spacing.unit,
    minWidth   : 150,
    marginTop  : '0.6rem',
    marginLeft : '1.3rem'
  },
  selectEmpty : {
    marginTop : theme.spacing.unit * 2
  },
  inputLabel  : {
    fontWeight : 'bold',
    fontSize   : '17px',
    color      : '#000'
  }
});

const PublishButton = ({ onClick, disabled }) => <ButtonWrapper disabled={disabled} onClick={onClick}><strong>PUBLISH</strong> SVR</ButtonWrapper>;
const EditButton    = ({ onClick, disabled }) => <ButtonWrapper disabled={disabled} onClick={onClick}><strong>EDIT</strong> SVR</ButtonWrapper>;
const PreviewButton = ({ onClick, disabled }) => <ButtonWrapper disabled={disabled} onClick={onClick}><strong>PREVIEW</strong> SVR</ButtonWrapper>;
const PreviewButtonuuid = ({ onClick, disabled }) => <ButtonWrapper disabled={disabled} onClick={onClick}><strong>PREVIEW UUID</strong> SVR</ButtonWrapper>;
const SaveNewButton = ({ onClick, disabled }) => <ButtonWrapper disabled={disabled} onClick={onClick}>SAVE AS <strong>NEW</strong></ButtonWrapper>;
const SaveButton    = ({ onClick, disabled }) => <ButtonWrapper disabled={disabled} onClick={onClick}><strong>SAVE</strong></ButtonWrapper>;

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const NUMBER_REGEX = /^\d+$/gm;

class SvrHeader extends Component {
  onTitleChange = ({ target }) => {
    this.props.onTitleChange(target.value);
  };

  onClientChange = ({ target }) => {
    this.props.onClientChange(target.value);
  };

  onAutoApproveChange = ({ target }) => {
    this.props.onAutoApproveChange(target.value);
  };

  onHideFromClientChange = ({ target }) => {
    this.props.onHideFromClientChange(target.value);
  };

  onInvoicePlannedTimeChange = ({ target }) => {
    this.props.onInvoicePlannedTimeChange(target.value);
  };

  onMaxAllowedTimeChange = ({ target }) => {
    this.props.onMaxAllowedTimeChange(R.compose(R.replace(/[^\d]/g, ''), R.defaultTo(''))(target.value));
  };

  onBypassGPSValidationChange = ({ target }) => {
    this.props.onBypassGPSValidationChange(target.value);
  };

  onBypassDuplicatePhotoCheckChange = ({ target }) => {
    this.props.onBypassDuplicatePhotoCheckChange(target.value);
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  onCallOutMailingChange = ({ target }) => {
    const { hasCallOut } = this.props;
    this.props.onCallOutChange({ active: hasCallOut, mailing: target.value });
  };

  hasInvalidCallOutMailing = () => {
    const { hasCallOut, callOutMailing } = this.props;
    return hasCallOut && R.compose(
      R.any(R.equals(false)),
      R.map(R.compose(R.test(EMAIL_REGEX), R.trim)),
      R.split(','),
      R.unless(R.is(String), R.always(''))
    )(callOutMailing);
  };

  hasInvalidMaxAllowredTime = () => {
    const { maxAllowedTime } = this.props;
    if(!maxAllowedTime) return false;

    return R.compose(
      R.equals(false),
      R.compose(R.anyPass([R.isNil, R.isEmpty, R.test(NUMBER_REGEX)]), R.trim),
    )(maxAllowedTime);
  };

  svrTimestamp = () => moment(this.props.formTimestamp).format('YYYY-MM-DD');

  doSave = () => {
    if (window.confirm('Are you sure?')) {
      return this.props.onSave();
    }
  };

  doSaveSvrForm = () => {
    if (window.confirm('Are you sure?')) {
      return this.props.onSaveSvrForm();
    }
  };

  getSecDocHost = (parmUrl) => {
    //debugger;
    if (document.URL !== undefined && document.URL !== null && document.URL !== "") {
      if (parmUrl.includes("wrsprodbucket.s3")) {
        if (document.URL.includes('https://mapps.wptools.wintellplus.com') || document.URL.includes('https://wapps.wptools.wintellplus.com')) {
          const tempdocsechost = "wrsdts.wptools.wintellplus.com";
          return tempdocsechost;
        }
        else if (document.URL.includes('https://wapps.mstage.wintellplus.com') || document.URL.includes('https://mapps.wpstage.wintellplus.com')) {
          const tempdocsechost = "wrsdts.mstage.wintellplus.com";
          return tempdocsechost;
        }
        else if (document.URL.includes("http://mapps.mstage.wintellplus.test")) {
          const tempdocsechost = "wrsdts.mstage.wintellplus.com";
          return tempdocsechost;
        } else {
          return null;
        }
      } else if (parmUrl.includes("wrsprodsecbucket.s3")) {
        if (document.URL.includes('https://mapps.wptools.wintellplus.com') || document.URL.includes('https://wapps.wptools.wintellplus.com')) {
          const tempdocsechost = "wrssfdc.wptools.wintellplus.com";
          return tempdocsechost;
        }
        else if (document.URL.includes('https://wapps.mstage.wintellplus.com') || document.URL.includes('https://mapps.wpstage.wintellplus.com')) {
          const tempdocsechost = "wrssfdc.mstage.wintellplus.com";
          return tempdocsechost;
        }
        else if (document.URL.includes("http://mapps.mstage.wintellplus.test")) {
          const tempdocsechost = "wrssfdc.mstage.wintellplus.com";
          return tempdocsechost;
        }
        else {
          return null;
        }
      }
    } else {
      return null;
    }
  }

  makeDecoratedImageUrl = url => {
    if (R.isNil(url) || R.isEmpty(url)) {
      return url;
    }
    const tempdocsechost = this.getSecDocHost(url);
  
    if (url.includes("wrsprodbucket.s3.amazonaws.com")) {
      const tempdocsecurls3 = url.replace("https://wrsprodbucket.s3.amazonaws.com", "");
      const tempdocsecurls3Clone =  "https://" +  tempdocsechost + tempdocsecurls3; 
      if (R.isNil(tempdocsecurls3Clone)) {
        return url;
      } else {
        return tempdocsecurls3Clone;
      }
    }
    else if (url.includes("wrsprodsecbucket.s3.amazonaws.com")) {
      const tempdocsecurls3 = url.replace("https://wrsprodsecbucket.s3.amazonaws.com", "");
      const tempdocsecurls3Clone =  "https://" + tempdocsechost + tempdocsecurls3; 
      if (R.isNil(tempdocsecurls3Clone)) {
        return url;
      } else {
        return tempdocsecurls3Clone;
      }
    }
    return url; 
  };

  render() {
    const {
      title,
      canonicalKey,
      formStatus,
      autoApprovalHourTrigger,
      hideFromClient,
      invoicePlannedTime,
      heroImageUrl,
      onSaveDraft,
      isSaving,
      isPreview,
      onPreview,
      isPreviewuuid,
      onPreviewuuid,
      onSaveNew,
      isEdit,
      currentClient,
      clientsList,
      classes,
      callOutMailing,
      maxAllowedTime,
      bypassGPSValidation,
      bypassDuplicatePhotoCheck
    } = this.props;

    const hasInvalidMailing = this.hasInvalidCallOutMailing();
    const hasInvalidMaxAllowredTime = this.hasInvalidMaxAllowredTime();

    const heroImageUrlClone =  this.makeDecoratedImageUrl(heroImageUrl);
    const ADMINISTRATOR_ROLE_ID = 1;
    const userRoleid = JSON.parse(JSON.parse(window.localStorage.getItem('persist:root')).auth).roleId;

    return (
      <div>
        <Wrapper>
          <TitleWrapper>
            <TextField
              fullWidth
              id="title"
              label="SVR NAME"
              value={title}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              autoFocus
              onChange={this.onTitleChange}
            />
          </TitleWrapper>
          <TitleWrapper>
            <TextField
              fullWidth
              id="client-select"
              select
              label="CLIENT"
              value={currentClient.clientId || ''}
              onChange={this.onClientChange}
              margin="normal"
            >
              {
                R.compose(
                  R.map(({ clientId, clientName}) => (
                    <MenuItem key= {clientId} value={clientId}>{clientName}</MenuItem>
                  )),
                  R.filter(R.prop('clientId'))
                )(clientsList)
              }
            </TextField>
          </TitleWrapper>
          <SaveWrapper>
            <SaveWrapperButtons>
              {
                isPreview &&
                  <PreviewButton
                  disabled={hasInvalidMailing || hasInvalidMaxAllowredTime || isSaving.status}
                  onClick={onPreview} />
              }
              {
                isPreviewuuid &&
                <PreviewButtonuuid
                  disabled={hasInvalidMailing || hasInvalidMaxAllowredTime || isSaving.status}
                  onClick={onPreviewuuid} />
              }
              {
                canonicalKey !== null &&
                  <EditButton
                    disabled={hasInvalidMailing || hasInvalidMaxAllowredTime || isSaving.status}
                    onClick={onSaveDraft}
                    formStatus={formStatus}
                  />
              }
              {
                (canonicalKey === null || formStatus === 3) &&
                  <PublishButton
                    type="save"
                    disabled={hasInvalidMailing || hasInvalidMaxAllowredTime || isSaving.status}
                    onClick={this.doSave}
                  />
              }
              {
                isEdit &&
                  <SaveNewButton
                  disabled={hasInvalidMailing || hasInvalidMaxAllowredTime || isSaving.status}
                  onClick={onSaveNew} />
              }
              {
                  <SaveButton
                  disabled={hasInvalidMailing || hasInvalidMaxAllowredTime || isSaving.status}
                  onClick={this.doSaveSvrForm} />
              }
            </SaveWrapperButtons>
            {
              canonicalKey !== null &&
                <VersionInformation>
                  <VersionInformationSpan>
                    <em><strong>Current mode:</strong> {formStatus === 3 ? `Draft ${this.svrTimestamp()}` : `Latest publish ${this.svrTimestamp()}`}</em>
                  </VersionInformationSpan>
                </VersionInformation>
            }
          </SaveWrapper>
        </Wrapper>
        <Wrapper>
          {
            autoApprovalHourTrigger !== 0 ?
              <FormControl className={classes.formControl}>
                <InputLabel className={classes.inputLabel} htmlFor="auto-approve-text">AUTO APPROVE</InputLabel>
                <Select
                  disabled={autoApprovalHourTrigger !== 0}
                  value={autoApprovalHourTrigger}
                  onChange={this.onAutoApproveChange}
                  input={<Input name="auto-approve" id="auto-approve-text" />}
                >
                  <MenuItem key="auto-approve-never" value={0}>Never</MenuItem>
                  <MenuItem key="auto-approve-12" value={12}>After 12 hours</MenuItem>
                  <MenuItem key="auto-approve-24" value={24}>After 24 hours</MenuItem>
                  <MenuItem key="auto-approve-36" value={36}>After 36 hours</MenuItem>
                </Select>
              </FormControl>
              : null
          }
          <FormControl className={classes.formControl}>
            <InputLabel className={classes.inputLabel} htmlFor="hide-from-client-text">HIDE FROM CLIENT</InputLabel>
            <Select
              value={hideFromClient}
              onChange={this.onHideFromClientChange}
              input={<Input name="hide-from-client" id="hide-from-client-text" />}
            >
              <MenuItem key="hide-from-client-no" value={0}>No</MenuItem>
              <MenuItem key="hide-from-client-yes" value={1}>Yes</MenuItem>
            </Select>
          </FormControl>
          <FormControl className={classes.formControl} style={{ minWidth: 190 , display: 'none' }}>
            <InputLabel className={classes.inputLabel} htmlFor="invoice-planned-time-text">INVOICE PLANNED TIME</InputLabel>
            <Select
              value={invoicePlannedTime}
              onChange={this.onInvoicePlannedTimeChange}
              input={<Input name="invoice-planned-time" id="invoice-planned-time-text" />}
            >
              <MenuItem key="invoice-planned-time-no" value={false}>No</MenuItem>
              <MenuItem key="invoice-planned-time-yes" value={true}>Yes</MenuItem>
            </Select>
          </FormControl>
          <FormControl className={classes.formControl} style={{ minWidth: 185 , display: 'none' }}>
            <InputLabel className={classes.inputLabel} style={{ fontSize : 15 }} htmlFor="bypass-gps-validation-text">BYPASS GPS VALIDATION</InputLabel>
            <Select
              value={bypassGPSValidation}
              onChange={this.onBypassGPSValidationChange}
              input={<Input name="bypass-gps-validation" id="bypass-gps-validation-text" />}
            >
              <MenuItem key="bypass-gps-validation-no" value={false}>No</MenuItem>
              <MenuItem key="bypass-gps-validation-yes" value={true}>Yes</MenuItem>
            </Select>
          </FormControl>
          <TitleWrapper style={{ flex: 0 , display: 'none'}}>
            <TextField style={{ width: 200 }}
              error={hasInvalidMaxAllowredTime}
              id="maxAllowedTime"
              label={`MAX ALLOWED ADDITIONAL TIME (minutes)`}
              value={maxAllowedTime}
              margin="normal"
              multiline
              InputLabelProps={{
                shrink: true,
                style: { fontSize : 15 }
              }}
              autoFocus
              onChange={this.onMaxAllowedTimeChange}
          />
          </TitleWrapper>
          <FormControl className={classes.formControl}>
            <InputLabel className={classes.inputLabel} htmlFor="hero-image" shrink>HERO IMAGE</InputLabel>
            <HeroImageUploader id="hero-image" heroImageUrl={heroImageUrlClone} />
          </FormControl>
          <FormControl className={classes.formControl} style={{ minWidth: 185 }}>
            <InputLabel className={classes.inputLabel} style={{ fontSize : 15 }} htmlFor="bypass-duplicate-photo-check-text">BYPASS DUPLICATE PHOTO CHECK</InputLabel>
            <Select disabled={userRoleid !== ADMINISTRATOR_ROLE_ID}
              value={bypassDuplicatePhotoCheck}
              onChange={this.onBypassDuplicatePhotoCheckChange}
              input={<Input name="bypass-duplicate-photo-check" id="bypass-duplicate-photo-check-text" />}
            >
              <MenuItem key="bypass-duplicate-photo-check-no" value={false}>No</MenuItem>
              <MenuItem key="bypass-duplicate-photo-check-yes" value={true}>Yes</MenuItem>
            </Select>
          </FormControl>
        </Wrapper>
        <Wrapper>
          <CallOutMailingWrapper>
            <TextField
              error={hasInvalidMailing}
              fullWidth
              id="callOutMailing"
              label={`CALL OUT MAILING LIST (comma separated.${(hasInvalidMailing && ' Please fix') || ''})`}
              value={callOutMailing}
              margin="normal"
              multiline
              InputLabelProps={{
                shrink: true,
              }}
              autoFocus
              onChange={this.onCallOutMailingChange}
            />
          </CallOutMailingWrapper>
        </Wrapper>
      </div>
    );
  }
}

SvrHeader.propTypes = {
  title                             : PropTypes.string,
  formStatus                        : PropTypes.number,
  canonicalKey                      : PropTypes.string,
  formTimestamp                     : PropTypes.string,
  autoApprovalHourTrigger           : PropTypes.number,
  hideFromClient                    : PropTypes.number,
  invoicePlannedTime                : PropTypes.bool,
  heroImageUrl                      : PropTypes.string,
  isPreview                         : PropTypes.bool,
  isPreviewuuid                     : PropTypes.bool,
  isEdit                            : PropTypes.bool,
  disabled                          : PropTypes.bool,
  onSaveNew                         : PropTypes.func.isRequired,
  onTitleChange                     : PropTypes.func.isRequired,
  onAutoApproveChange               : PropTypes.func.isRequired,
  onHideFromClientChange            : PropTypes.func.isRequired,
  onInvoicePlannedTimeChange        : PropTypes.func.isRequired,
  onMaxAllowedTimeChange            : PropTypes.func.isRequired,
  onBypassGPSValidationChange       : PropTypes.func.isRequired,
  onBypassDuplicatePhotoCheckChange : PropTypes.func.isRequired,
  onSave                            : PropTypes.func.isRequired,
  onSaveDraft                       : PropTypes.func.isRequired,
  currentClient                     : PropTypes.object,
  onSelectChange                    : PropTypes.func,
  clientsList                       : PropTypes.array,
  callOutMailing                    : PropTypes.string,
  maxAllowedTime                    : PropTypes.string,
  bypassGPSValidation               : PropTypes.bool,
  onCallOutChange                   : PropTypes.func.isRequired,
  bypassDuplicatePhotoCheck         : PropTypes.bool
};

SvrHeader.defaultProps = {
  title                     : '',
  formStatus                : 1,
  canonicalKey              : null,
  formTimestamp             : '',
  heroImageUrl              : '',
  autoApprovalHourTrigger   : 0,
  hideFromClient            : 0,
  invoicePlannedTime        : false,
  isEdit                    : false,
  isPreview                 : false,
  isPreviewuuid             : false,
  callOutMailing            : '',
  maxAllowedTime            : '',
  bypassGPSValidation       : false,
  bypassDuplicatePhotoCheck : false
};

export default withStyles(styles)(SvrHeader);
